<template>
  <div id="login">
    <!-- 后台登录页 -->
    <div class="content">
      <div class="title">
        欢迎使用
        <div class="litter-title">旅游小程序后台管理系统</div>
      </div>
      <div class="line">
        <el-input v-model="userName" clearable placeholder="请输入账号">
          <i slot="prefix" class="el-icon-user-solid"></i>
        </el-input>
      </div>
      <div class="line">
        <el-input
          v-model="password"
          show-password
          clearable
          placeholder="请输入密码"
          @keyup.enter.native="login"
        >
          <i slot="prefix" class="el-icon-lock"></i>
        </el-input>
      </div>
      <div class="login" @click="login">登录</div>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/login/login.js";
export default {
  data() {
    return {
      userName: process.env.VUE_APP_USER,
      password: process.env.VUE_APP_USER,
    };
  },
  methods: {
   
    async login() {
      let requestData = {
        userName: this.userName,
        password: this.password,
      };
      let loginData = await login(requestData);
      console.log("loginData",loginData);
      localStorage.setItem("isToken",loginData.token)

      let tag = this.$menu.returnTag(
        "项目列表",
        { id: "" },
        "/project-list",
        "项目列表"
      );
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push({
        path: tag.path,
        query: {
          id: "",
        },
      });
      this.$router.push("/project-list");
    },
  },
  mounted() {
    this.$store.dispatch("tagsView/delAllViews");
    localStorage.clear();
  },
};
</script>
<style lang="less" scoped>
#login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // background-image: url("../../assets/img/login_bg.png");
  background-size: 100% 100%;

  > .content {
    width: 450px;
    height: 550px;
    background-color: #fff;
    margin-right: 250px;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    padding: 40px;

    > .title {
      width: 100%;
      height: 30%;
      display: flex;
      flex-direction: column;
      font-size: 35px;
      font-weight: 800;
      color: #165fa8;
      letter-spacing: 3px;

      > .litter-title {
        font-size: 30px;
        font-weight: 600;
        margin-top: 20px;
        color: #3c4eae;
      }
    }

    > .line {
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      /deep/.el-input__prefix {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3c4eae;
        font-size: 17px;
      }

      /deep/.el-input__inner {
        height: 50px;
      }
    }

    > .login {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #3c4eae;
      color: #fff;
      letter-spacing: 3px;
      font-size: 18px;
      font-weight: 600;
      margin-top: 40px;
      cursor: pointer;
      transition: 0.3;

      &:hover {
        background-color: #2c3983;
      }
    }
  }
}
</style>
