import Vue from 'vue'
export function initMenu() {
    let menu = [
        // {
        //     path: "/index",
        //     fullPath: "/index",
        //     query: {},
        //     name: "首页",
        //     icon: "el-icon-s-home",
        //     meta: {
        //         affix: true,
        //         title: "首页",
        //         activeMenu: '首页'
        //     },
        // },
        {
            path: "",
            fullPath: "",
            query: {},
            name: "项目管理",
            icon: "el-icon-folder-opened",
            meta: {
                title: "项目列表",
            },
            children: [{
                path: "/project-list",
                fullPath: "/project-list",
                query: {},
                name: "项目列表",
                meta: {
                    title: "项目列表",
                    activeMenu: '项目列表'
                },
            },{
                path: "/route-list",
                fullPath: "/route-list",
                query: {},
                name: "行程列表",
                meta: {
                    title: "行程列表",
                    activeMenu: '行程列表'
                },
            },]
        }, 
        
        // {
        //     path: "",
        //     fullPath: "",
        //     query: {},
        //     name: "行程中心",
        //     icon: "el-icon-s-data",
        //     meta: {
        //         title: "行程中心",
        //     },
        //     children: [{
        //         path: "/route-list",
        //         fullPath: "/route-list",
        //         query: {},
        //         name: "行程列表",
        //         meta: {
        //             title: "行程列表",
        //             activeMenu: '行程列表'
        //         },
        //     }, ]
        // }, 
        
        {
            path: "",
            fullPath: "",
            query: {},
            name: "订单管理",
            icon: "el-icon-s-order",
            meta: {
                title: "订单管理",
            },
            children: [{
                path: "/order-list",
                fullPath: "/order-list",
                query: {},
                name: "所有订单",
                meta: {
                    title: "所有订单",
                    activeMenu: '所有订单'
                },
            }, ]
        }, {
            path: "",
            fullPath: "",
            query: {},
            name: "标签管理",
            icon: "el-icon-price-tag",
            meta: {
                title: "系统设置",
            },
            children: [{
                path: "/tag-list",
                fullPath: "/tag-list",
                query: {},
                name: "标签列表",
                meta: {
                    title: "标签列表",
                    activeMenu: '标签列表'
                },
            }, ]
        },
        {
            path: "",
            fullPath: "",
            query: {},
            name: "个人信息",
            icon: "el-icon-user",
            meta: {
                title: "",
            },
            children: [{
                path: "/edit-person",
                fullPath: "/edit-person",
                query: {},
                name: "个人信息",
                meta: {
                    title: "个人信息",
                    activeMenu: '个人信息'
                },
            }, 
            {
                path: "/edit-account",
                fullPath: "/edit-account",
                query: {},
                name: "修改账号",
                meta: {
                    title: "修改账号",
                    activeMenu: '修改账号'
                },
            },]
        },

    ]
    return menu
}


export function returnTagByName(name) {
    let menuList = initMenu()
    return getTagByName(name, menuList)
}

function getTagByName(name, list) {
    try {
        list.forEach(element => {
            if (element.name == name) {
                throw element
            }
            if (element.children && element.children.length > 0) {
                let tag = getTagByName(name, element.children)
                if (tag != "") {
                    throw tag
                }
            }
        });
        throw ""
    } catch (msg) {
        return msg
    }
}

/**
 * 跳转的tag标签
 * @param {*} name 标签名称
 * @param {*} query 标签携带的参数
 * @param {*} path 跳转链接
 * @param {*} activeMenu 左侧相应菜单
 */
export function returnTag(name, query, path, activeMenu) {
    let tag = {
        path: path,
        fullPath: path,
        query: query,
        name: name,
        meta: {
            title: name,
            activeMenu: activeMenu
        },
    }
    return tag
}